import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberLoadingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spaces: global.userStaff,
      selection: false
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = lang.t('header:title.welcomepage')+' - PT Mate'
    window.scrollTo(0, 0)
    global.programLocked = false

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('spaceInfoLoaded', (event) => this.setTrainers())
    this.configureData()

  }
  

  configureData() {
    var found = false
    for(var item of global.userStaff) {
      if(item.id === global.uid) {
        found = true
      }
    }
    if(global.userStaff.length === 1) {
      global.uid = global.userStaff[0].id
      found = true
    }
    if(global.uid !== '' && found && !this.state.selection) {
      this.props.history.push('/'+global.uid+'/home')
    } else {
      this.setState({
        spaces: global.userStaff,
        selection: true
      })
      for(var item2 of global.userStaff) {
        Connector.getSpaceInfo(item2)
      }
    }
  }


  setTrainers() {
    var found = false
    this.setState({
      spaces: global.userStaff,
    })
    if(global.userStaff.length === 1) {
      global.uid = global.userStaff[0].id
      found = true
    }
    if(global.uid !== '' && found) {
      this.props.history.push('/'+global.uid+'/home')
    }
  }


  


  selectSpace(item) {
    global.uid = item.id
    global.userBusiness = item.name
    global.spaceOwner = item.owner
    global.spaceImage = item.image
    global.spaceClient = item.client
    global.userCommunity = item.community
    global.userLimitBooking = item.limitBooking
    global.userCommunityPost = item.communityPost
    global.userAllowBooking = item.allowBooking
    global.spaceAllowRecurring = item.allowRecurring
    global.num = item.num
    global.userStripeConnect = item.stripe
    global.clientToken = item.token
    Connector.loadSessions((result) => {})
    Connector.loadEvents((result) => {})
    Connector.loadClient((result) => {
      if(global.userParent !== '' && global.userParent !== undefined) {
        Connector.loadFamilyBilling(global.userParent)
      }
      this.props.history.push('/'+global.uid+'/home')
    })
    Connector.loadClientGroups((result) => {})
    Connector.loadPayments((result) => {})
    Connector.loadInvoices((result) => {})
    Connector.loadLog((result) => {})
    Connector.loadChat((result) => {})
    Connector.loadChatsGroup((result) => {})
    Connector.loadClients((result) => {})
    Connector.loadProducts((result) => {})
    Connector.loadPrograms((result) => {})
    Connector.loadCommunity((result) => {})
    Connector.loadRecurring((result) => {})
    Connector.loadHabits((result) => {})
    Connector.loadDocuments((result) => {})
    Connector.loadTraining((result) => {})
    Connector.loadExercises((result) => {})
    Connector.loadLocations((result) => {})
    Connector.loadSchedule((result) => {})
    if(item.theme !== undefined) {
      global.spaceTheme = item.theme
    } else {
      global.spaceTheme = 'blue'
    }
    if(item.image !== '') {
      Firebase.storage().ref().child(item.image).getDownloadURL().then((url) => {
        global.spaceImage = url
        EventEmitter.dispatch('userLoaded', 'loaded');
      }).catch((error) => {
        // Handle any errors
      })
    }
  }


  logoutUser() {
    global.uid = "";
    global.uidUser = "";
    global.userName = '';
    global.userBusiness = '';
    global.userEmail = '';
    global.userPhone = '';
    global.userStripe = '';
    global.userMessage = 0;
    global.userCard = '';
    global.userSubPlanId = '';
    global.userSubPlan = 'spark';
    global.userSubId = '';
    global.userSubStatus = 'trialing';
    global.userSubBilling = '';
    global.userSubCancel = false;
    global.userSubEnd = 0;
    global.userSubItemId = '';
    global.userStripeConnect = '';
    global.userStripeBank = '';
    global.userStripeAddress = '';
    global.timerStart = 5;
    global.userTemplates = [];
    global.userImage = '';
    global.userEmailsSent = [];
    global.userVerified = false;
    global.userOnboarding = ['', ''];
    global.userBadges = [];
    global.userComments = true;
    global.userShowBooked = true;
    global.userSubdomain = '';

    global.cards = [];
    global.coupon = [];
    global.userSignup = false;

    global.userRole = '';
    global.userStaff = [];
    global.spaces = [];
    global.clientPlans = [];

    global.publicData = [];
    global.publicRequests = [];
    global.showOnboarding = false;
    global.userConnectClient = null;
    global.clientToken = '';
    global.clientTokens = [];
    global.spaceClient = '';
    global.spaceTheme = 'blue';

    // Data arrays

    global.clients = [];
    global.clientGroups = [];
    global.clientsImages = [];
    global.clientsInactive = [];
    global.sessions = [];
    global.archive = [];
    global.events = []
    global.clientBest = [];
    global.clientBestGroup = [];
    global.chats = [];
    global.chatsGroup = [];
    global.activity = [];

    global.sessionsTraining = [];
    global.archiveTraining = [];
    global.recurring = [];

    global.programs = [];
    global.programsClient = [];
    global.plans = [];
    global.exercises = [];

    global.payments = [];
    global.invoices = [];
    global.products = [];

    global.userLog = [];
    global.syncDate = 0;
    global.maxClients = 0;
    global.maxPrograms = 0;
    global.maxPlans = 0;
    global.maxTrainers = 0;

    global.userDataLoaded = false;
    Firebase.auth().signOut()
  }



  // Display stuff ------------------------------------------------------------



  renderImage(item) {
    if(item.image === '') {
      return (
        <div className={'member-image bg'+item.num}>
          <div className={'gradient theme-'+item.theme}></div>
        </div>
      )
    } else {
      return (
        <div className="member-image" style={{backgroundImage: 'url('+item.image+')'}}></div>
      )
    }
  }


  renderBase() {
    if(this.state.spaces.length === 0) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content member">
            <div className="empty large pt-60" style={{opacity: '.3'}}>
              <h3>{lang.t('auth:label.loading2')}</h3>  
            </div>
          </div>
          <div style={{position: 'fixed', top: 15, right: 15}}>
            <button className="btn tertiary small close" onClick={() => this.logoutUser()}>{lang.t('nav:account.logout')}</button>
          </div>
        </div>
      )
    } else {
      var list = this.state.spaces
      list.sort((a, b) => a.name.localeCompare(b.name))
      return (
        <div>
          <div className="content">
            <div className="listheader">
              <h2>{lang.t('header:auth.select')}</h2>
              <div className="clear"></div>
            </div>
            {list.map(item => (
              <div key={item.id} onClick={() => this.selectSpace(item)}>
                <div className="box list highlight simple mb-10">
                  {this.renderImage(item)}
                  <h4>{item.name}</h4>
                  <p>{item.owner}<br/>{lang.t('auth:label.selectspace')}</p>
                  <div className="clear"></div>
                </div>
                <div className="clear space-20"></div>
              </div>
            ))}
          </div>
          <div style={{position: 'fixed', top: 15, right: 15}}>
            <button className="btn tertiary small close" onClick={() => this.logoutUser()}>{lang.t('nav:account.logout')}</button>
          </div>
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['auth','nav','header'])(withRouter(withAuthorizationMember(condition)(MemberLoadingPage)));